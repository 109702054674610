import { PagedSearchTerms } from "./PagedSearchTerms";


export class KpiReportSearchTerms extends PagedSearchTerms {
    constructor(obj: Object = {},
        page: number = 1,
        pageSize: number = 10) {
        super(page, pageSize);
        obj = obj || {};
        if (obj.hasOwnProperty("quickSearch"))  this.quickSearch = obj["quickSearch"];
        // if (obj.hasOwnProperty("organization")) this.organization = obj["organization"];
    }

    quickSearch?: string;
    organization?: number;

    toQry(): string {
        const terms = [];
        if (this.quickSearch) {
            terms.push(`quickSearch=${encodeURIComponent(this.quickSearch)}`);
        }
        
        terms.push(super.toQry());
        return terms.join("&");
    }

    resetToDefault() {
        this.quickSearch = undefined;
        this.organization = undefined;
    }
}


