import { ReportingFrequency } from "models/ReportingFrequency";
import { ReportingTerm } from "models/ReportingTerm";

export class ReportingTerms {
    yearlyReportingTerms = [
        { id: ReportingTerm.T1, description: "Y 1/1" }
    ];

    halfYearlyReportingTerms = [
        { id: ReportingTerm.T1, description: "H-Y 1/2" },
        { id: ReportingTerm.T2, description: "H-Y 2/2" }
    ];

    everyFourMonthReportingTerms = [
        { id: ReportingTerm.T1, description: "T 1/3" },
        { id: ReportingTerm.T2, description: "T 2/3" },
        { id: ReportingTerm.T3, description: "T 3/3" }
    ];

    quarterlyReportingTerms = [
        { id: ReportingTerm.T1, description: "Q 1/4" },
        { id: ReportingTerm.T2, description: "Q 2/4" },
        { id: ReportingTerm.T3, description: "Q 3/4" },
        { id: ReportingTerm.T4, description: "Q 4/4" }
    ];

    everyTwoMonthReportingTerms = [
        { id: ReportingTerm.T1, description: "B-M 1/6" },
        { id: ReportingTerm.T2, description: "B-M 2/6" },
        { id: ReportingTerm.T3, description: "B-M 3/6" },
        { id: ReportingTerm.T4, description: "B-M 4/6" },
        { id: ReportingTerm.T5, description: "B-M 5/6" },
        { id: ReportingTerm.T6, description: "B-M 6/6" }
    ];

    monthlyReportingTerms = [
        { id: ReportingTerm.T1, description: "M 1/12" },
        { id: ReportingTerm.T2, description: "M 2/12" },
        { id: ReportingTerm.T3, description: "M 3/12" },
        { id: ReportingTerm.T4, description: "M 4/12" },
        { id: ReportingTerm.T5, description: "M 5/12" },
        { id: ReportingTerm.T6, description: "M 6/12" },
        { id: ReportingTerm.T7, description: "M 7/12" },
        { id: ReportingTerm.T8, description: "M 8/12" },
        { id: ReportingTerm.T9, description: "M 9/12" },
        { id: ReportingTerm.T10, description: "M 10/12" },
        { id: ReportingTerm.T11, description: "M 11/12" },
        { id: ReportingTerm.T12, description: "M 12/12" }
    ];

    constructor(public reportingFrequency: ReportingFrequency) { }

    getReportingTerms() {
        switch(this.reportingFrequency) {
            case ReportingFrequency.Yearly:
                return this.yearlyReportingTerms;

            case ReportingFrequency.HalfYearly:
                return this.halfYearlyReportingTerms;

            case ReportingFrequency.EveryFourMonth:
                return this.everyFourMonthReportingTerms;

            case ReportingFrequency.Quarterly:
                return this.quarterlyReportingTerms;

            case ReportingFrequency.EveryTwoMonth:
                return this.everyTwoMonthReportingTerms;

            case ReportingFrequency.Monthly:
                return this.monthlyReportingTerms;
        }
    }
}
