import { MemberData } from "./MemberData";
import { MeetingProgramData } from "./MeetingProgramData";
import { TeachingLearningProgramData } from "./TeachingLearningProgramData";
import { MaterialData } from "./MaterialData";
import { LibraryStockData } from "./LibraryStockData";
import { FinanceData } from "./FinanceData";
import { SocialWelfareData } from "./SocialWelfareData";

export class ReportData {
    constructor(public associateMemberData: MemberData,
        public preliminaryMemberData: MemberData,
        public supporterMemberData: MemberData,
        public memberMemberData: MemberData,
        public workerMeetingProgramData: MeetingProgramData,
        public cmsMeetingProgramData: MeetingProgramData,
        public smMeetingProgramData: MeetingProgramData,
        public memberMeetingProgramData: MeetingProgramData,
        public dawahMeetingProgramData: MeetingProgramData,
        public stateLeaderMeetingProgramData: MeetingProgramData,
        public stateOutingMeetingProgramData: MeetingProgramData,
        public iftarMeetingProgramData: MeetingProgramData,
        public learningMeetingProgramData: MeetingProgramData,
        public socialDawahMeetingProgramData: MeetingProgramData,
        public dawahGroupMeetingProgramData: MeetingProgramData,
        public nextGMeetingProgramData: MeetingProgramData,
        public tafsirMeetingProgramData: MeetingProgramData,
        public unitMeetingProgramData: MeetingProgramData,
        public bbqMeetingProgramData: MeetingProgramData,
        public gatheringMeetingProgramData: MeetingProgramData,
        public familyVisitMeetingProgramData: MeetingProgramData,
        public eidReunionMeetingProgramData: MeetingProgramData,
        public otherMeetingProgramData: MeetingProgramData,
        public groupStudyTeachingLearningProgramData: TeachingLearningProgramData,
        public studyCircleForAssociateMemberTeachingLearningProgramData: TeachingLearningProgramData,
        public studyCircleTeachingLearningProgramData: TeachingLearningProgramData,
        public practiceDarsTeachingLearningProgramData: TeachingLearningProgramData,
        public stateLearningCampTeachingLearningProgramData: TeachingLearningProgramData,
        public quranStudyTeachingLearningProgramData: TeachingLearningProgramData,
        public hadithTeachingLearningProgramData: TeachingLearningProgramData,
        public quranClassTeachingLearningProgramData: TeachingLearningProgramData,
        public weekendIslamicSchoolTeachingLearningProgramData: TeachingLearningProgramData,
        public memorizingAyatTeachingLearningProgramData: TeachingLearningProgramData,
        public memorizingHadithTeachingLearningProgramData: TeachingLearningProgramData,
        public memorizingDoaTeachingLearningProgramData: TeachingLearningProgramData,
        public stateLearningSessionTeachingLearningProgramData: TeachingLearningProgramData,
        public stateQiyamulLailTeachingLearningProgramData: TeachingLearningProgramData,
        public otherTeachingLearningProgramData: TeachingLearningProgramData,
        public bookSaleMaterialData: MaterialData,
        public bookDistributionMaterialData: MaterialData,
        public vhsSaleMaterialData: MaterialData,
        public vhsDistributionMaterialData: MaterialData,
        public emailDistributionMaterialData: MaterialData,
        public ipdcLeafletDistributionMaterialData: MaterialData,
        public otherSaleMaterialData: MaterialData,
        public otherDistributionMaterialData: MaterialData,
        public bookLibraryStockData: LibraryStockData,
        public vhsLibraryStockData: LibraryStockData,
        public otherLibraryStockData: LibraryStockData,
        public baitulMalFinanceData: FinanceData,
        public aDayMasjidProjectFinanceData: FinanceData,
        public masjidTableBankFinanceData: FinanceData,
        public qardeHasanaSocialWelfareData: SocialWelfareData,
        public patientVisitSocialWelfareData: SocialWelfareData,
        public socialVisitSocialWelfareData: SocialWelfareData,
        public transportSocialWelfareData: SocialWelfareData,
        public shiftingSocialWelfareData: SocialWelfareData,
        public shoppingSocialWelfareData: SocialWelfareData,
        public foodDistributionSocialWelfareData: SocialWelfareData,
        public cleanUpAustraliaSocialWelfareData: SocialWelfareData,
        public otherSocialWelfareData: SocialWelfareData,
        public comment: string,
    ) {}
}
